import * as serializers from '@/constants/serializer';
import { ENUM } from '@/constants/viewTypes';
import * as comparators from '@/constants/comparators';

import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

const linkedProperties = {
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'parentUnit',
  INVENTORY: 'inventory',
  STATUS: 'status',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    label: 'label.header',
    prop: 'header',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'room.external_id',
    prop: 'externalId',
    type: schemaItemTypes.STRING,
    disabled: true,
  },
  {
    label: 'room.name',
    prop: 'name',
    type: schemaItemTypes.STRING,
    rules: [rules.REQUIRED],
  },
  {
    label: 'label.project',
    prop: linkedProperties.PROJECT,
    type: schemaItemTypes.PROJECT,
    rules: [rules.REQUIRED],
    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.BUILDING,
      },
    ],
  },
  {
    label: 'label.building',
    prop: linkedProperties.BUILDING,
    type: schemaItemTypes.BUILDING,
    rules: [rules.REQUIRED],
    payload: [
      {
        param: 'projectId',
        from: [linkedProperties.PROJECT, 'id'],
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: linkedProperties.PROJECT,
        from: 'project',
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.UNIT,
      },
    ],
  },
  {
    type: schemaItemTypes.UNIT,
    label: 'label.unit',
    prop: linkedProperties.UNIT,
    rules: [rules.REQUIRED],
    payload: [
      { param: 'buildingId', from: [linkedProperties.BUILDING, 'id'] },
      { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: linkedProperties.BUILDING,
        from: 'building',
      },
    ],
  },
  {
    label: 'room.total_area',
    prop: 'internalArea',
    type: schemaItemTypes.SQUARE,
    rules: [rules.REQUIRED],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'room.rent_amount',
    prop: 'goalRentCost',
    type: schemaItemTypes.NUMBER,
  },
  {
    label: 'unit.status',
    type: schemaItemTypes.TITLE,
    titleAppend: {
      type: schemaItemTypes.UNIT_CONTRACTS,
      prop: 'id',
    },
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.radio,
    dictionary: dictionaryTypes.ROOM_STATUSES,
    prop: linkedProperties.STATUS,
    defaultValue: 'rented',
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'rentalPeriodDate',
    rules: [rules.REQUIRED],
    label: 'client.rent',
    tooltip: {
      text: 'room.rental_period_tooltip',
      icon: 'mdi-information',
    },
    payload: [
      {
        param: 'multiple',
        from: [linkedProperties.STATUS],
        serializer: serializers.IS_RENTED,
      },
    ],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['rented'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'unit.tenant',
    prop: 'tenant',
    rules: [rules.REQUIRED],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['rented'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'salesContractDate',
    label: 'client.sales_contract_date',
    rules: [rules.REQUIRED],
    tooltip: {
      text: 'room.sales_contract_date_tooltip',
      icon: 'mdi-information',
    },
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['sold'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'unit.owner',
    prop: 'owner',
    rules: [rules.REQUIRED],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['sold'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
];

export { schema as default };
